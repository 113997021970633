<template>
  <div>
    <b-row v-if="drivers.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="drivers"
            :fields="fields"
            head-row-variant="primary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(gender)="data">
              <span v-if="data.item.gender == 1"> Мужской </span>
              <span v-else> Женский </span>
            </template>
            <template #cell(balance.balance)="data">
              <router-link
                :to="{
                  name: 'balance',
                  query: { driver_id: data.item.balance.performer_id },
                }"
                v-if="data.item.balance != null"
              >
                <div style="color: blue">
                  {{ data.item.balance.balance }}
                </div>
              </router-link>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";

export default {
  data() {
    return {
      drivers: undefined,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "balance.balance",
          label: "Баланс",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "first_name",
          label: "Имя",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "last_name",
          label: "Фамилия",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "patronymic",
          label: "Отчество",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "date_of_birth",
          label: "Дата рождения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "phone",
          label: "Телефон",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "contact_number",
          label: "Доп. номер тел.",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "serials_number",
          label: "Номер вод.удостоверения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expirated_driver_license",
          label: "Срок истечения вод. удостоверение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "gender",
          label: "Пол",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "serial_number_passport",
          label: "Номер паспорта",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expirated_passport",
          label: "Срок истечения паспорта",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "passport_office",
          label: "Орган, выдающий документ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "address",
          label: "Адрес",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "district",
          label: "Район",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);

    resizeable(),
      this.$http
        .get(`/cars/${this.$route.query.filter}/drivers`)
        .then((res) => {
          this.drivers = res.data;
          this.$store.commit("pageData/setdataCount", this.drivers.length);
        });
  },
  watch: {
    "$route.query.filter": function (newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$http
          .get(`/cars/${this.$route.query.filter}/drivers`)
          .then((res) => {
            this.drivers = res.data;
            this.$store.commit("pageData/setdataCount", this.drivers.length);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
